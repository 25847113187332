import React, { useState } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as XLSX from 'xlsx';

const ExcelTable = ({ year, dispatch, createAudit, fetchAuditsByYear }) => {
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Получение данных как массив массивов

            if (jsonData.length > 0) {
                setColumns(jsonData[0]); // Установим заголовки из первой строки
                setTableData(
                    jsonData.slice(1).map((row) => ({
                        data: row,
                        startDate: null,
                        endDate: null, // Инициализация дат
                    }))
                );
            }
        };
        reader.readAsBinaryString(file);
    };

    const handleDateChange = (rowIndex, field, newValue) => {
        setTableData((prevData) =>
            prevData.map((row, index) =>
                index === rowIndex ? { ...row, [field]: newValue } : row
            )
        );
    };

    const handleDeleteClick = (rowIndex) => {
        setTableData((prevData) => prevData.filter((_, index) => index !== rowIndex));
    };

    const handleAddClick = async (rowIndex) => {
        const row = tableData[rowIndex];
        const name = row.data[2]; // Из третьей колонки
        const start_date = row.startDate ? row.startDate.format('YYYY-MM-DD') : null;
        const end_date = row.endDate ? row.endDate.format('YYYY-MM-DD') : null;

        if (!name || !start_date || !end_date) {
            alert('Заполните имя, дату начала и дату окончания.');
            return;
        }

        try {
            await dispatch(
                createAudit({
                    name,
                    start_date,
                    end_date,
                    year,
                })
            ).unwrap();
            dispatch(fetchAuditsByYear(year));
            alert('Аудит добавлен успешно.');
            setTableData((prevData) => prevData.filter((_, index) => index !== rowIndex));
        } catch (error) {
            console.error('Error adding audit:', error);
            alert('Ошибка при добавлении аудита.');
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <input
                    accept=".xlsx, .xls"
                    type="file"
                    onChange={handleFileUpload}
                    style={{ marginBottom: '20px' }}
                />
                {tableData.length > 0 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {columns.map((col, index) => (
                                        <TableCell key={index}>{col}</TableCell>
                                    ))}
                                    <TableCell>Дата начала</TableCell>
                                    <TableCell>Дата окончания</TableCell>
                                    <TableCell>Действия</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {row.data.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>{cell}</TableCell>
                                        ))}
                                        <TableCell>
                                            <DatePicker
                                                value={row.startDate}
                                                onChange={(newValue) =>
                                                    handleDateChange(rowIndex, 'startDate', newValue)
                                                }
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <DatePicker
                                                value={row.endDate}
                                                onChange={(newValue) =>
                                                    handleDateChange(rowIndex, 'endDate', newValue)
                                                }
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAddClick(rowIndex)}
                                                style={{ marginRight: '10px' }}
                                            >
                                                Добавить
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleDeleteClick(rowIndex)}
                                            >
                                                Удалить
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </LocalizationProvider>
    );
};

export default ExcelTable;
