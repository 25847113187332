import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  auth: false,
};

const authSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    login: (state) => {
      state.auth = true;
    },
    logout: (state) => {
      state.auth = false;
      localStorage.removeItem('access_token'); // Очистка токена при logout
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state) => {
      // Проверка токена при загрузке
      const token = localStorage.getItem('access_token');
      if (token) {
        state.auth = true;
      }
    });
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;