import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Button,
    Container,
    Grid,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
} from '@mui/material';
import {
    fetchAuditYears,
    fetchAuditsByYear,
    createAudit,
    removeAudit,
} from '../store/auditSlice';
import { sendMessage, downloadExcelTable } from '../store/chatSlice';
import Chat from '../components/chat/chat';
import styles from './planPage.module.css';
import ExcelTable from '../components/excelTable/excel-table';

const PlanPage = () => {
    const dispatch = useDispatch();
    const years = useSelector((state) => state.audits.years);
    const audits = useSelector((state) => state.audits.audits);
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);
    const chat = useSelector((state) => state.chat);

    const [prompt, setPrompt] = useState('');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [number, setNumber] = useState(1);
    const [newAudit, setNewAudit] = useState({
        name: '',
        start_date: '',
        end_date: '',
        year: selectedYear,
    });

    useEffect(() => {
        dispatch(fetchAuditYears());
    }, [dispatch]);

    useEffect(() => {
        if (years.length > 0) {
            setSelectedYear(Math.max(...years));
        }
    }, [years]);

    useEffect(() => {
        dispatch(fetchAuditsByYear(selectedYear));
    }, [selectedYear, dispatch]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleNumberChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value) && value > 0) {
            setNumber(value);
        }
    };

    const handleNewAuditInputChange = (e) => {
        const { name, value } = e.target;
        setNewAudit({ ...newAudit, [name]: value });
    };

    const handleAddAudit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(createAudit(newAudit)).unwrap();
            setNewAudit({ name: '', start_date: '', end_date: '', year: selectedYear });
            dispatch(fetchAuditsByYear(selectedYear));
        } catch (error) {
            console.error('Error adding audit:', error);
        }
    };

    const handleDeleteAudit = async (auditId) => {
        try {
            await dispatch(removeAudit(auditId)).unwrap();
            dispatch(fetchAuditsByYear(selectedYear));
        } catch (error) {
            console.error('Error deleting audit:', error);
        }
    };

    const handleSend = () => {
        console.log(`Prompt: ${prompt}, Year: ${selectedYear}, Number: ${number}`);
        dispatch(sendMessage({ message: prompt, prompt: 'CUSTOM_PROMPT' }));
        setPrompt('');
    };

    const handleCreatePlan = () => {
        const message = `Создай план проверок в количестве ${number}, на ${selectedYear} год.`;
        console.log(`Creating Plan: ${message}`);
        dispatch(
            sendMessage({
                message: message,
                prompt: 'AUDIT_PLAN_CREATOR',
            })
        );
    };

    const getLastAiMessage = () => {
        const messages = chat.messages;
    
        if (!messages || messages.length === 0) {
            return null; // Если сообщений нет
        }
    
        // Ищем последнее сообщение от AI
        const lastAiMessage = [...messages].reverse().find((msg) => msg.user === 'AI');
        return lastAiMessage ? lastAiMessage.text : null;
    };

    const handleDownloadExcelSubmit = () => {
        const message = getLastAiMessage();
        dispatch(downloadExcelTable({ message })).catch((error) =>
            console.error('Error downloading Excel file:', error)
        );
    };


    return (
        <Container>
            <Grid container spacing={2}>
                {/* Левая часть с Chat */}
                <Grid item xs={4}>
                    <Chat
                        prompt={prompt}
                        onPromptChange={setPrompt}
                        additionalButtons={
                            <>
                                <FormControl sx={{ minWidth: 120, marginRight: 1 }}>
                                    <InputLabel id="year-select-label">Year</InputLabel>
                                    <Select
                                        labelId="year-select-label"
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                    >
                                        {years.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Number"
                                    type="number"
                                    inputProps={{ min: 1 }}
                                    value={number}
                                    onChange={handleNumberChange}
                                    sx={{ width: '100px', marginRight: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSend}
                                >
                                    Send
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreatePlan}
                                >
                                    Create Plan
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleDownloadExcelSubmit}
                                >
                                    Download Excel
                                </Button>
                            </>
                        }
                    />
                </Grid>

                {/* Правая часть с управлением аудитами */}
                <Grid item xs={8}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>Plan Page</h1>
                        <div className={styles.yearSelector}>
                            <label htmlFor="year">Select Year: </label>
                            <select
                                id="year"
                                value={selectedYear}
                                onChange={handleYearChange}
                                className={styles.select}
                            >
                                {years.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <form onSubmit={handleAddAudit} className={styles.form}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Audit Name"
                                value={newAudit.name}
                                onChange={handleNewAuditInputChange}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="start_date"
                                value={newAudit.start_date}
                                onChange={handleNewAuditInputChange}
                                required
                                className={styles.input}
                            />
                            <input
                                type="date"
                                name="end_date"
                                value={newAudit.end_date}
                                onChange={handleNewAuditInputChange}
                                required
                                className={styles.input}
                            />
                            <input
                                type="number"
                                name="year"
                                value={newAudit.year}
                                onChange={(e) => setNewAudit({ ...newAudit, year: e.target.value })}
                                required
                                className={styles.input}
                            />
                            <button type="submit" className={styles.button}>
                                Add Audit
                            </button>
                        </form>
                        {auditStatus === 'loading' && <div>Loading...</div>}
                        {auditStatus === 'failed' && <div className={styles.error}>{error}</div>}
                        <ul className={styles.auditList}>
                            {audits.map((audit) => (
                                <li key={audit.id} className={styles.auditItem}>
                                    {audit.name} ({audit.start_date} - {audit.end_date})
                                    <button
                                        onClick={() => handleDeleteAudit(audit.id)}
                                        className={styles.deleteButton}
                                    >
                                        Delete
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Grid>
            </Grid>
            <div style={{ marginTop: '20px' }}>
            <ExcelTable
                year={selectedYear}
                dispatch={dispatch}
                createAudit={createAudit}
                fetchAuditsByYear={fetchAuditsByYear}
            />
            </div>
        </Container>
    );
};

export default PlanPage;
