import React, { useState } from 'react';
import './header.scss';
import png from '../../resources/images/avatar@1x.png';
import Avatar from '../avatar';
import { Button } from '../common/button';
import { InputText } from '../common/form-control';
import { Menu, MenuItem } from '@mui/material';

const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Удаляем токены из локального хранилища
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        
        // Закрываем меню
        setAnchorEl(null);
        
        // Можно добавить логику перенаправления, например:
        window.location.href = '/login';
        console.log('Токены удалены, пользователь вышел из системы');
    };

    return (
        <header className="header">
            <div className="header__left-part">
                <Button 
                    type="button"
                    classes="btn-icon-circle"
                    title="Меню"
                    leftIcon="icon-menu"
                />
                <div className="search-control">
                    <InputText 
                        id="search"
                        label="Поиск"
                        placeholder="Поиск"
                        isScreenReaderOnly
                    />
                    <Button 
                        type="button"
                        classes="btn-icon"
                        title="Поиск"
                        leftIcon="icon-search"
                    />
                </div>
            </div>
            <div className="header__right-part">
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Избранное"
                    leftIcon="icon-heart _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon _with-indicator"
                    title="Уведомления"
                    leftIcon="icon-notification _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Сообщения"
                    leftIcon="icon-chat _color-blue"
                />
                <Button 
                    type="button"
                    classes="btn-icon"
                    title="Настройки"
                    leftIcon="icon-settings _color-blue"
                />
                <div>
                    <Avatar 
                        src={png} 
                        alt="Аватар Иванова Ивана" 
                        onClick={handleAvatarClick} 
                        style={{ cursor: 'pointer'}}
                    />
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    MenuListProps={{
                        sx: { boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' },
                    }}
                    PaperProps={{
                        style: {
                            maxHeight: '200px',
                        },
                    }}
                    keepMounted
                >
                    <MenuItem onClick={handleMenuClose}>Профиль</MenuItem>
                    <MenuItem 
                        onClick={handleLogout} 
                        sx={{ color: 'red' }}
                    >
                        Выйти
                    </MenuItem>
                </Menu>
            </div>
        </header>
    );
};

export default Header;
