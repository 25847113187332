import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL_PLANNING, fetchWithAuth, getHeaders } from '../api/utils';
import Swal from "sweetalert2";

export const sendMessage = createAsyncThunk(
    'chat/sendMessage',
    async ({ message, prompt }, { rejectWithValue }) => {
        try {
            const url = `${BASE_URL_PLANNING}/api/gpt/get-ai-response/`;
            const data = await fetchWithAuth(url, {
                method: 'POST',
                headers: getHeaders(true),
                body: JSON.stringify({ message, prompt }),
            });

            return {
                userMessage: message,
                botResponse: data.response.response, // Извлекаем ответ бота
            };
        } catch (error) {
            return rejectWithValue(error.message || 'Unknown error occurred');
        }
    }
);

export const downloadExcelTable = createAsyncThunk(
    'chat/downloadExcelTable',
    async ({ message }, { rejectWithValue }) => {
        try {
            console.log('Sending request to download Excel file...');
            const url = `${BASE_URL_PLANNING}/api/gpt/download_excel_table_from_text/`;
            const blob = await fetchWithAuth(
                url,
                {
                    method: 'POST',
                    body: JSON.stringify({ message }),
                },
                true // Указываем, что ожидаем бинарный ответ
            );

            console.log('Blob size:', blob.size);
            console.log('Blob type:', blob.type);

            const fileName = 'GeneratedTable.xlsx'; // Имя файла по умолчанию
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            console.log('File download initiated successfully.');
            return { userMessage: message };
        } catch (error) {
            console.error('Download error:', error.message);
            return rejectWithValue(error.message || 'Error generating Excel file');
        }
    }
);


const initialState = {
    messages: [], // Список всех сообщений
    status: 'idle', // idle, loading, succeeded, failed
    error: null,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        clearChatHistory: (state) => {
            state.messages = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending, (state) => {
                state.status = 'loading';
                Swal.fire({
                    title: "Запрос успешно отправлен",
                    icon: "success",
                    toast: true,
                    timer: 2000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                  });
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.messages.push({ user: 'User', text: action.payload.userMessage }); // Сообщение пользователя
                state.messages.push({ user: 'AI', text: action.payload.botResponse }); // Ответ бота
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Something went wrong';
            })
            .addCase(downloadExcelTable.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(downloadExcelTable.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.messages.push({ user: 'User', text: 'Скачай таблицу в Excel' }); // Сообщение пользователя
                state.messages.push({ user: 'AI', text: 'Excel table generated and downloaded.' }); // Уведомление об успешной загрузке
            })
            .addCase(downloadExcelTable.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Error generating Excel file';
                Swal.fire({
                    title: "Ошибка отправки запроса",
                    text: action.payload,
                    icon: "error",
                    toast: true,
                    timer: 2000,
                    position: "top-right",
                    timerProgressBar: true,
                    showConfirmButton: false,
                });
                console.log(action.payload);
            });
    },
});

export const { clearChatHistory } = chatSlice.actions;
export default chatSlice.reducer;
