// api/utils.js

import { refreshAccessToken } from './auth';

export const BASE_URL_PLANNING = "http://femida.pro:8001";

export const cacheHeaders = {
    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
    'Pragma': 'no-cache',
};

export const getHeaders = (isAuth = false) => {
    const headers = {
        ...cacheHeaders,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
    if (isAuth) {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            headers['Authorization'] = `Bearer ${accessToken}`;
        }
    }
    return headers;
};

export const handleResponse = async (response, isBinary = false) => {
    if (!response.ok) {
        const errorText = await response.text(); // Читаем ошибку как текст
        console.error('Request failed:', errorText);
        throw new Error(`Request failed: ${response.statusText}`);
    }

    if (response.status === 204) {
        // Если ответ 204 No Content, просто возвращаем null
        return null;
    }

    if (isBinary) {
        return response.blob(); // Возвращаем бинарные данные
    }

    try {
        return await response.json(); // По умолчанию возвращаем JSON
    } catch (error) {
        console.warn('Failed to parse JSON response:', error);
        return null; // Вернем null, если JSON отсутствует
    }
};


export const fetchWithAuth = async (url, options = {}, isBinary = false) => {
    const headers = getHeaders(true);
    console.log('Sending request to:', url);
    console.log('Request options:', { ...options, headers });

    let response = await fetch(url, { ...options, headers });

    console.log('Response status:', response.status);

    if (response.status === 401) {
        const newAccessToken = await refreshAccessToken();
        headers['Authorization'] = `Bearer ${newAccessToken}`;
        response = await fetch(url, { ...options, headers });
    }

    return handleResponse(response, isBinary); // Передаем флаг isBinary
};

