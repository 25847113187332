import TeamList from '../components/teamList';
import ProjectSchedule from '../components/projectSchedule';
import AuditProgram from '../components/auditProgram';
import mockTeamListData from '../components/teamList/mockTeamList';
import mockProjectScheduleData from '../components/projectSchedule/mockProjectSchedule';
import mockAuditProgram from '../components/auditProgram/mockAuditProgram';
import Chat from '../components/chat/chat';
import { Button, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage, downloadExcelTable } from '../store/chatSlice';

const AuditPage = () => {
    const dispatch = useDispatch();
    const chat = useSelector((state) => state.chat);
    const audits = useSelector((state) => state.audits.audits); // Данные из store > audits > audits

    const [prompt, setPrompt] = useState('');
    const [selectedAudit, setSelectedAudit] = useState(''); // Хранение выбранного значения

    const handleEmptyPromptSubmit = () => {
        if (prompt.trim()) {
            dispatch(
                sendMessage({
                    message: prompt,
                    prompt: 'EMPTY',
                })
            );
            setPrompt('');
        }
    };

    const handleProgramCreatorSubmit = () => {
        if (selectedAudit.trim()) {
            dispatch(
                sendMessage({
                    message: selectedAudit,
                    prompt: 'AUDIT_PROGRAM_CREATOR',
                })
            );
            setPrompt('');
        }
    };

    const getLastAiMessage = () => {
        const messages = chat.messages;

        if (!messages || messages.length === 0) {
            return null;
        }

        const lastAiMessage = [...messages].reverse().find((msg) => msg.user === 'AI');
        return lastAiMessage ? lastAiMessage.text : null;
    };

    const handleDownloadExcelSubmit = () => {
        const message = getLastAiMessage();
        dispatch(downloadExcelTable({ message })).catch((error) =>
            console.error('Error downloading Excel file:', error)
        );
    };

    const handleAuditChange = (event) => {
        setSelectedAudit(event.target.value);
    };

    return (
        <>
            <h1 className='main-title'>Аудит управления ИТ-мощностями</h1>
            <Chat
                prompt={prompt}
                onPromptChange={setPrompt}
                additionalButtons={
                    <>
                        <Select
                            value={selectedAudit}
                            onChange={handleAuditChange}
                            displayEmpty
                            style={{ marginRight: '10px' }}
                        >
                            <MenuItem value="" disabled>
                                Выберите аудит
                            </MenuItem>
                            {audits.map((audit) => (
                                <MenuItem key={audit.id} value={audit.name}>
                                    {audit.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEmptyPromptSubmit}
                        >
                            Send
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleProgramCreatorSubmit}
                        >
                            Create Program
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleDownloadExcelSubmit}
                        >
                            Download Excel
                        </Button>
                    </>
                }
            />
            <AuditProgram programTitle="Программа аудита" program={mockAuditProgram} />
            <TeamList title="Команда" list={mockTeamListData} />
            <ProjectSchedule title="График проекта" shedule={mockProjectScheduleData} />
        </>
    );
};

export default AuditPage;
