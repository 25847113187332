import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper, TextField, Typography } from '@mui/material';

const Chat = ({ prompt, onPromptChange, additionalButtons }) => {
    const chat = useSelector((state) => state.chat);
    const chatBoxRef = useRef(null);

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [chat.messages]);

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    flexGrow: 1, // Растягиваемая область
                    overflowY: 'auto', // Прокрутка при переполнении
                    border: '1px solid #ccc',
                    padding: 2,
                    borderRadius: 1,
                    marginBottom: 2,
                }}
                ref={chatBoxRef}
            >
                <Typography variant="h6" gutterBottom>
                    Chat Visualization
                </Typography>
                {chat.messages.map((entry, index) => (
                    <Typography key={index} variant="body1" gutterBottom>
                        <strong>{entry.user}:</strong> {entry.text}
                    </Typography>
                ))}
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <TextField
                    fullWidth
                    value={prompt}
                    onChange={(e) => onPromptChange(e.target.value)}
                    placeholder="Enter your message"
                    variant="outlined"
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, flexWrap: 'wrap' }}>
                {additionalButtons &&
                    React.Children.map(additionalButtons, (button) =>
                        React.cloneElement(button, {
                            prompt, // Передаем значение ввода в кнопки
                        })
                    )}
            </Box>
        </Paper>
    );
};

export default Chat;
